<template>
    <div class="order">
        <div @click="goBack" class="goBack">
            <span><i class="el-icon-arrow-left"></i> My Orders</span>
        </div>
        <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
                <div style="font-size: 20px;color: #333;font-weight: bold;">{{ Sub.orderNo }}</div>
                <div class="sus" :style="getOrderStyle(Sub)">{{ getStatusText(Sub.status) }}</div>
                <div style="font-size: 14px;color: #959595;margin-left: 18px;">
                    {{ Sub.createTime }}
                </div>
            </div>
            <div>
                <div>
                    <button class="export" @click="export1()">
                        <img src="../../assets/imgs/uploads/ion_share-outline.png" alt="" style="width: 15px;height: 15px;">
                        Export to Excel</button>
                </div>
            </div>
        </div>
        <div style="font-size: 14px;color: #959595;">If you want to modify the order quantity or delete it, please contact
            our salesman by email. lf you want to add items, please place a new order.</div>
        <!-- 我的订单主要内容 -->
        <div class="order-content" v-if="orderSubs.length > 0">
            <div class="content">
                <div class="tit">
                    Order details
                </div>
                <div>
                    <div v-for="(item, index) in orderSubs" :key="index" class="list">
                        <div class="goods">
                            <div>
                                <img class="pic" v-lazy="item.imageUrl" />
                            </div>
                            <div style="margin-left: 18px;font-size: 14px;">
                                <div style="color: #333;margin-bottom: 8px;">{{ item.skuName }}</div>
                                <div style="color: #000;">Unit Price: ${{ item.price }}</div>
                            </div>
                        </div>
                        <div style="font-size: 14px;text-align: center;">
                            <div style="margin-bottom: 8px;">Quantity</div>
                            <div>{{ item.num }}</div>
                        </div>
                        <div style="width: 140px;font-size: 14px;text-align: right;">
                            <div style="margin-bottom: 8px;">Amount</div>
                            <div>$ {{ item.amount }}</div>
                        </div>
                    </div>
                    <div class="btm2">
                        <div style="color: #959595;">
                            <div>Selected <span style="color: #333;">{{ Sub.skuQty }}</span> products</div>
                            <div>Total <span style="color: #333;">{{ Sub.totalNum }}</span> items</div>
                        </div>

                        <div style="width: 250px;font-weight: bold;text-align: right;">
                            <div>Goods Amount: ${{ Sub.goodsAmount }}</div>
                            <div>Shipping Cost: ${{ Sub.carriage }}</div>
                            <div>
                                Grand Total: ${{ Sub.totalAmount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="details">
                    <div style="font-weight: bold;margin-bottom: 41px;">
                        Shipping details
                    </div>
                    <div style="font-weight: bold;font-size: 12px;color: #959595;">Order Contact</div>
                    <div style="margin-bottom: 50px;margin-top: 13px;color: #545454;">
                        <div>{{ user.nickName }}</div>
                        <div>{{ user.email }}</div>
                        <div>{{ user.phone }}</div>
                    </div>
                    <div style="font-weight: bold;font-size: 12px;color: #959595;margin-bottom: 12px;">Shipping Address
                    </div>
                    <div style="color: #545454;">
                        <div>{{ add.nickName }}</div>
                        <div>{{ add.address }}</div>
                        <div>{{ add.country }}</div>
                        <div>{{ add.zipCode }}</div>
                    </div>
                </div>
                <div class="tables">
                    <div style="font-weight: bold;margin-bottom: 20px;">
                        Time Table
                    </div>
                    <div>ETD: {{ dtime }}</div>
                    <div>ETA: {{ atime }}</div>
                </div>
            </div>

        </div>
        <!-- 我的订单主要内容END -->
        <!-- 订单为空的时候显示的内容 -->
        <div v-else class="order-empty">
            <div class="empty">
                <h2>{{ $t('order.empty') }}</h2>
            </div>
        </div>
        <!-- 订单为空的时候显示的内容END -->
    </div>
</template>
  
<script>
import { saveAs } from "file-saver"
export default {
    data() {
        return {
            orderSubs: [],// 订单列表
            Sub: [],
            user: '',
            add: '',
            atime: '',
            dtime: '',
        };
    },
    mounted() {
        this.activated()
        this.getuser()
        this.getOrderAddress()
    },
    methods: {
        // getTotalSum() {
        //     var totalSum = 0;
        //     for (var i = 0; i < this.orderSubs.length; i++) {
        //         totalSum = totalSum * 1000 + (this.orderSubs[i].amount * 1000)
        //         totalSum = totalSum / 1000
        //     }
        //     return totalSum
        // },
        getOrderAddress() {
            this.$axios
                .post("/api/mall/orderSub/getOrderAddress", { id: this.$route.query.id })
                .then(res => {
                    if (res.data.code === 200) {
                        this.add = res.data.data;
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        activated() {
            // 获取订单数据
            this.$axios
                .post("/api/mall/orderSub/" + this.$route.query.id)
                .then(res => {
                    if (res.data.code === 200) {
                        this.orderSubs = res.data.data;
                        this.Sub = this.orderSubs[0];
                        this.dtime = this.time(this.Sub.sailingTime)
                        this.atime = this.time(this.Sub.arrivalTime)
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        time(value) {
            if (value !== null && value !== "" && value !== undefined) {
                let date = new Date(value);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                month = month < 10 ? "0" + month : month;
                day = day < 10 ? "0" + day : day;
                date = year + "-" + month + "-" + day;
                return date;
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        getOrderStyle(item) {
            return {
                color: this.getBorderColor(item.status),
                backgroundColor: this.getStatusColor(item.status),
                borderColor: this.getBorderColor(item.status),
            };
        },
        getStatusText(status) {
            switch (status) {
                case 0:
                    return 'Pending';
                case 1:
                    return 'processing';
                case 2:
                    return 'delivering';
                default:
                    return 'null';
            }
        },
        getStatusColor(status) {
            switch (status) {
                case 0:
                    return '#EDE2FF';
                case 1:
                    return '#FFF5CE';
                case 2:
                    return '#D9D9D9';
                default:
                    return 'white';
            }
        },
        getBorderColor(status) {
            switch (status) {
                case 0:
                    return '#8A43FF';
                case 1:
                    return '#FFB800';
                case 2:
                    return '#959595';
                default:
                    return 'black';
            }
        },
        getuser() {
            this.$axios
                .post("/api/mall/orderSub/getUserDetail", {
                    id: this.$store.getters.getUser.id
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.user = res.data.data;
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        export1() {
            this.$axios
                .post("/api/mall/orderSub/getSubExportList", {
                    orderId: this.$route.query.id
                }, { responseType: "blob" })
                .then(res => {
                    const obj = res.data
                    saveAs(obj, '订单明细.xlsx')
                })
        }
    }

}
</script>
  
<style scoped>
.order-content {
    display: flex;
    align-items: flex-start;
}

.order {
    padding-bottom: 20px;
}

.content {
    width: 708px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-top: 18px;
    padding: 10px;
    padding-bottom: 40px;
}

/* 订单为空的时候显示的内容CSS */
.order .order-empty {
    width: 1225px;
    margin: 0 auto;
}

.order .order-empty .empty {
    height: 300px;
    padding: 0 auto;
    margin: 65px 0 0;
    color: #b0b0b0;
    overflow: hidden;
}

.order .order-empty .empty h2 {
    margin: 70px 0 15px;
    font-size: 36px;
}

.order .order-empty .empty p {
    margin: 0 0 20px;
    font-size: 20px;
}

.kuanshi {
    color: #855d5d;
    font-size: 14px;
    margin-left: 10px;
}

.goBack {
    font-size: 16px;
    font-weight: bold;
    color: #959595;
    cursor: pointer;
    margin-bottom: 16px;
}

.tit {
    margin: 11px 0 11px 28px;
    font-size: 16px;
    font-weight: bold;
    color: #545454;
}

.list {
    height: 106px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
    align-items: center;
}

.goods {
    margin-left: 28px;
    display: flex;
    align-items: center;
    width: 340px;
}

.pic {
    height: 80px;
    width: 80px;
    border: 2px solid #D9D9D9;
    border-radius: 5px;
}

.details {
    width: 381px;
    height: 385px;
    margin-top: 18px;
    margin-left: 12px;
    padding-left: 27px;
    padding-top: 21px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

.tables {
    width: 381px;
    height: 150px;
    margin-top: 18px;
    margin-left: 12px;
    padding-left: 27px;
    padding-top: 21px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

.btm2 {
    margin-top: 14px;
    margin-left: 28px;
    display: flex;
    justify-content: space-between;
}

.sus {
    width: 83px;
    height: 22px;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    border-radius: 23px;
    border: 1px solid #959595;
}

.export {
    width: 128px;
    height: 36px;
    margin-right: 15px;
    margin-left: 22px;
    border: 1px solid #FFA9A4;
    border-radius: 5px;
    background-color: #FFE7E5;
    color: #CA141D;
    cursor: pointer;
}
</style>